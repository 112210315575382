import axios from 'axios'

export default {
  updatePrice (price) {
    return axios.put('prices/' + price.id, price).then(response => {
      return response.data
    })
  },

  createPrice (price) {
    return axios.post('prices', price).then(response => {
      return response.data
    })
  },

  deletePrice (id) {
    return axios.delete('prices/' + id).then(response => {
      return response.data
    })
  },

  fetchPrice (id) {
    return axios.get('prices/' + id + '?expand=priceSummary,course')
      .then(response => {
        return response.data
      })
  },

  fetchPrices () {
    return axios.get('prices?expand=priceSummary,course').then(response => {
      return response.data
    })
  },

  orderUp (id) {
    return axios.post('prices/order', { id: id, move: 'up' }).then(response => {
      return response.data
    })
  },

  orderDown (id) {
    return axios.post('prices/order', { id: id, move: 'down' }).then(response => {
      return response.data
    })
  },

  fetchCurrencies () {
    return axios.get('prices/currencies').then(response => {
      return response.data
    })
  },

}
