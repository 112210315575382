<template>
  <v-container
    id="client-forms"
    fluid
    tag="section"
  >
    <v-container v-if="loading">
      <div class="text-center">
        <v-progress-circular
          indeterminate
          :size="150"
          :width="8"
          color="green"
        />
      </div>
    </v-container>
    <v-row justify="center">
      <v-col
        v-if="!loading"
        cols="12"
        md="12"
        class="mt-0 mb-0 pt-0 pb-0"
      >
        <v-breadcrumbs
          class="mt-0 mb-0 pt-0 pb-0"
          :items="[{text: 'Ваш центр', to: '/'}, {text: 'Цены на обучение', to: '/prices'}, {text: title}]"
          large
        />
      </v-col>
      <v-col
        cols="12"
        md="10"
        lg="8"
      >
        <base-material-alert
          v-if="error"
          dark
          color="error"
          dismissible
        >
          {{ error }}
        </base-material-alert>

        <base-material-card
          v-if="!loading"
          color="success"
          icon="mdi-badge-account"
          class="px-5 py-3"
          :title="title"
        >
          <v-form
            ref="form"
            class="mt-5"
            lazy-validation
          >
            <v-row
              justify="center"
            >
              <v-col
                v-if="price.status === 0"
                cols="12"
                md="12"
              >
                <base-material-alert
                  v-if="price.status === 0"
                  color="warning"
                  dark
                  icon="mdi-zip-disk"
                >
                  Цены в архиве!
                </base-material-alert>
              </v-col>
              <v-col
                cols="12"
                md="9"
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="5"
                    class="mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-text-field
                      v-model="price.name"
                      required
                      label="Название*"
                      :error-messages="fieldError('name')"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="1"
                    class="text-center align-center mb-0 pb-0 mt-0 pt-0"
                  >
                    <span>или</span>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                    class="text-center align-center mb-0 pb-0 mt-0 pt-0"
                  >
                    <v-select
                      v-model="price.course_id"
                      :error-messages="fieldError('course_id')"
                      label="Курс"
                      item-value="id"
                      item-text="name"
                      :items="courses"
                    />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    cols="12"
                    md="8"
                  >
                    <v-textarea
                      v-model="price.comment"
                      :error-messages="fieldError('comment')"
                      rows="2"
                      auto-grow
                      label="Дополнительная информация"
                    />
                  </v-col>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="price.type"
                      :error-messages="fieldError('type')"
                      label="Язык/тип занятия"
                      item-value="id"
                      item-text="name"
                      :items="types"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md="3"
              >
                <v-col
                  cols="12"
                  md="12"
                  class="text-center align-center mb-0 pb-0 mt-0 pt-0"
                >
                  <v-select
                    v-model="price.currency"
                    :error-messages="fieldError('currency')"
                    label="Валюта*"
                    item-value="id"
                    item-text="name"
                    :items="currencies"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="12"
                >
                  <v-tooltip
                    right
                    max-width="50%"
                  >
                    <template v-slot:activator="{ on }">
                      <div
                        class="card-title font-weight-light"
                        v-on="on"
                      >
                        <v-checkbox
                          v-model="price.action"
                          :error-messages="fieldError('action')"
                          label="Акция"
                          :false-value="0"
                          :true-value="1"
                          messages="Информация о цене будет выводится с пометкой &laquo;Акция!&raquo;."
                        />
                      </div>
                    </template>
                    <span>Ставьте данную пометку только на отдельные позиции,<br>опция предназначена для выделения одной,<br>максимум двух позиций в прайсе центра.</span>
                  </v-tooltip>
                </v-col>
              </v-col>
            </v-row>
            <v-row
              class="text-center align-center mb-0 pb-0 mt-0 pt-0"
            >
              <v-col
                cols="12"
                md="10"
                class="text-center align-center mb-0 pb-0 mt-0 pt-0"
              >
                <v-text-field
                  v-model="price.price1name"
                  required
                  label="Описание 1"
                  :error-messages="fieldError('price1name')"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="text-center align-center mb-0 pb-0 mt-0 pt-0"
              >
                <v-text-field
                  v-model="price.price1"
                  required
                  label="Цена 1"
                  :error-messages="fieldError('price1')"
                />
              </v-col>
            </v-row>
            <v-row
              class="text-center align-center mb-0 pb-0 mt-0 pt-0"
            >
              <v-col
                cols="12"
                md="10"
                class="text-center align-center mb-0 pb-0 mt-0 pt-0"
              >
                <v-text-field
                  v-model="price.price2name"
                  required
                  label="Описание 2"
                  :error-messages="fieldError('price2name')"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="text-center align-center mb-0 pb-0 mt-0 pt-0"
              >
                <v-text-field
                  v-model="price.price2"
                  required
                  label="Цена 2"
                  :error-messages="fieldError('price2')"
                />
              </v-col>
            </v-row>
            <v-row
              class="text-center align-center mb-0 pb-0 mt-0 pt-0"
            >
              <v-col
                cols="12"
                md="10"
                class="text-center align-center mb-0 pb-0 mt-0 pt-0"
              >
                <v-text-field
                  v-model="price.price3name"
                  required
                  label="Описание 3"
                  :error-messages="fieldError('price3name')"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="text-center align-center mb-0 pb-0 mt-0 pt-0"
              >
                <v-text-field
                  v-model="price.price3"
                  required
                  label="Цена 3"
                  :error-messages="fieldError('price3')"
                />
              </v-col>
            </v-row>
            <v-row
              class="text-center align-center mb-0 pb-0 mt-0 pt-0"
            >
              <v-col
                cols="12"
                md="10"
                class="text-center align-center mb-0 pb-0 mt-0 pt-0"
              >
                <v-text-field
                  v-model="price.price4name"
                  required
                  label="Описание 4"
                  :error-messages="fieldError('price4name')"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="text-center align-center mb-0 pb-0 mt-0 pt-0"
              >
                <v-text-field
                  v-model="price.price4"
                  required
                  label="Цена 4"
                  :error-messages="fieldError('price4')"
                />
              </v-col>
            </v-row>
            <v-row
              class="text-center align-center mb-0 pb-0 mt-0 pt-0"
            >
              <v-col
                cols="12"
                md="10"
                class="text-center align-center mb-0 pb-0 mt-0 pt-0"
              >
                <v-text-field
                  v-model="price.price5name"
                  required
                  label="Описание 5"
                  :error-messages="fieldError('price5name')"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="text-center align-center mb-0 pb-0 mt-0 pt-0"
              >
                <v-text-field
                  v-model="price.price5"
                  required
                  label="Цена 5"
                  :error-messages="fieldError('price5')"
                />
              </v-col>
            </v-row>
            <v-row
              class="text-center align-center mb-0 pb-0 mt-0 pt-0"
            >
              <v-col
                cols="12"
                md="10"
                class="text-center align-center mb-0 pb-0 mt-0 pt-0"
              >
                <v-text-field
                  v-model="price.price6name"
                  required
                  label="Описание 6"
                  :error-messages="fieldError('price6name')"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="text-center align-center mb-0 pb-0 mt-0 pt-0"
              >
                <v-text-field
                  v-model="price.price6"
                  required
                  label="Цена 6"
                  :error-messages="fieldError('price6')"
                />
              </v-col>
            </v-row>
            <v-row
              class="text-center align-center mb-0 pb-0 mt-0 pt-0"
            >
              <v-col
                cols="12"
                md="10"
                class="text-center align-center mb-0 pb-0 mt-0 pt-0"
              >
                <v-text-field
                  v-model="price.price7name"
                  required
                  label="Описание 7"
                  :error-messages="fieldError('price7name')"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="text-center align-center mb-0 pb-0 mt-0 pt-0"
              >
                <v-text-field
                  v-model="price.price7"
                  required
                  label="Цена 7"
                  :error-messages="fieldError('price7')"
                />
              </v-col>
            </v-row>
            <v-row
              class="text-center align-center mb-0 pb-0 mt-0 pt-0"
            >
              <v-col
                cols="12"
                md="10"
                class="text-center align-center mb-0 pb-0 mt-0 pt-0"
              >
                <v-text-field
                  v-model="price.price8name"
                  required
                  label="Описание 8"
                  :error-messages="fieldError('price8name')"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="text-center align-center mb-0 pb-0 mt-0 pt-0"
              >
                <v-text-field
                  v-model="price.price8"
                  required
                  label="Цена 8"
                  :error-messages="fieldError('price8')"
                />
              </v-col>
            </v-row>
            <v-row
              class="text-center align-center mb-0 pb-0 mt-0 pt-0"
            >
              <v-col
                cols="12"
                md="10"
                class="text-center align-center mb-0 pb-0 mt-0 pt-0"
              >
                <v-text-field
                  v-model="price.price9name"
                  required
                  label="Описание 9"
                  :error-messages="fieldError('price9name')"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="text-center align-center mb-0 pb-0 mt-0 pt-0"
              >
                <v-text-field
                  v-model="price.price9"
                  required
                  label="Цена 9"
                  :error-messages="fieldError('price9')"
                />
              </v-col>
            </v-row>
            <v-row
              class="text-center align-center mb-0 pb-0 mt-0 pt-0"
            >
              <v-col
                cols="12"
                md="10"
                class="text-center align-center mb-0 pb-0 mt-0 pt-0"
              >
                <v-text-field
                  v-model="price.price10name"
                  required
                  label="Описание 10"
                  :error-messages="fieldError('price10name')"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="text-center align-center mb-0 pb-0 mt-0 pt-0"
              >
                <v-text-field
                  v-model="price.price10"
                  required
                  label="Цена 10"
                  :error-messages="fieldError('price10')"
                />
              </v-col>
            </v-row>
            <v-row
              class="text-center align-center mb-0 pb-0 mt-0 pt-0"
            >
              <v-col
                cols="12"
                md="10"
                class="text-center align-center mb-0 pb-0 mt-0 pt-0"
              >
                <v-text-field
                  v-model="price.price11name"
                  required
                  label="Описание 11"
                  :error-messages="fieldError('price11name')"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="text-center align-center mb-0 pb-0 mt-0 pt-0"
              >
                <v-text-field
                  v-model="price.price11"
                  required
                  label="Цена 11"
                  :error-messages="fieldError('price11')"
                />
              </v-col>
            </v-row>
            <v-row
              class="text-center align-center mb-0 pb-0 mt-0 pt-0"
            >
              <v-col
                cols="12"
                md="10"
                class="text-center align-center mb-0 pb-0 mt-0 pt-0"
              >
                <v-text-field
                  v-model="price.price12name"
                  required
                  label="Описание 12"
                  :error-messages="fieldError('price12name')"
                />
              </v-col>
              <v-col
                cols="12"
                md="2"
                class="text-center align-center mb-0 pb-0 mt-0 pt-0"
              >
                <v-text-field
                  v-model="price.price12"
                  required
                  label="Цена 12"
                  :error-messages="fieldError('price12')"
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-card-actions class="pl-0">
                  <v-btn
                    color="success"
                    min-width="100"
                    @click="savePrice()"
                  >
                    Сохранить
                  </v-btn>

                  <v-btn
                    v-if="price.status === 1"
                    color="warning"
                    min-width="100"
                    @click="archive()"
                  >
                    Переместить в архив
                  </v-btn>

                  <v-btn
                    v-if="price.status === 0 && price.id > 0"
                    color="warning"
                    min-width="100"
                    @click="unarchive()"
                  >
                    Вернуть из архива
                  </v-btn>

                  <v-btn
                    v-if="price.status === 0 && price.id > 0"
                    color="error"
                    class="ma-1"
                    min-width="100"
                    @click="confirmDialog = true"
                  >
                    Удалить
                  </v-btn>
                </v-card-actions>
              </v-col>
            </v-row>
          </v-form>
        </base-material-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="confirmDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          Удалить цену?
        </v-card-title>

        <v-card-text>
          Цена будет удалена без возможности восстановления.
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="darken-1"
            text
            @click="confirmDialog = false"
          >
            Отмена
          </v-btn>

          <v-btn
            color="error darken-1"
            text
            @click="removePrice(price.id)"
          >
            Удалить
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import moment from 'moment'
  import priceApi from '../services/PriceApi'
  import coursesApi from '../services/CoursesApi'

  export default {
    filters: {
      formatDateFromUnixTime: function (unixtime) {
        return moment.unix(unixtime).format('DD.MM.YYYY HH:mm')
      },
    },

    props: ['id'],
    data () {
      return {
        price: {
          id: null,
          name: null,
        },
        loading: true,
        error: null,
        fieldsErrors: [],
        fieldError (fieldName) {
          let errors = []
          if (this.fieldsErrors) {
            this.fieldsErrors.filter(field => {
              if (field.field === fieldName) {
                errors.push(field.message)
              }
            })
          }
          return errors
        },
        confirmDialog: false,
        courses: [{ id: null, name: ' - не выбрано - ' }],
        currencies: [],
        types: [
          { id: null, name: ' - не выбрано - ' },
          { id: 'en', name: 'Английский' },
          { id: 'de', name: 'Немецкий' },
          { id: 'ch', name: 'Китайский' },
          { id: 'fr', name: 'Французский' },
          { id: 'es', name: 'Испанский' },
          { id: 'ru', name: 'Русский' },
        ],
      }
    },

    computed: {
      title () {
        if (this.price.name && this.price.course) return this.price.name + ' | ' + this.price.course.name
        if (this.price.name) return this.price.name
        if (this.price.course) return this.price.course.name
        if (this.id === 'create') return 'Добавить цены на обучение'
        return '#' + this.id
      },
    },

    mounted () {
      document.title = this.title + ' | ' + process.env.VUE_APP_NAME
      moment.locale('ru')
      coursesApi
        .fetchCourses()
        .then(response => {
          this.courses = this.courses.concat(response)
        })
        .catch(error => {
          this.error = this.pretty(error.response.data)
        })
      priceApi
        .fetchCurrencies()
        .then(response => {
          this.currencies = response
        })
        .catch(error => {
          this.error = this.pretty(error.response.data)
        })
      this.fetchData()
    },

    methods: {
      fetchData () {
        if (this.id === 'create') {
          this.loading = false
          this.price = {
            id: null,
            name: null,
            status: 1,
          }
        } else {
          priceApi
            .fetchPrice(this.id)
            .then(response => {
              this.price = response
              this.loading = false
              document.title = this.title + ' | ' + process.env.VUE_APP_NAME
            }).catch(error => {
              this.error = this.pretty(error.response.data)
            })
        }
      },

      back () {
        this.$router.push('/')
      },

      savePrice () {
        if (this.price.id) {
          this.error = null
          this.fieldsErrors = []
          this.loading = true
          priceApi
            .updatePrice(this.price)
            .then(response => {
              this.price = response
              this.fetchData()
            })
            .catch(error => {
              let data = error.response.data
              if (error.response.status === 422) {
                this.fieldsErrors = data
              } else {
                this.error = this.pretty(data)
              }
              this.loading = false
            })
        } else {
          priceApi
            .createPrice(this.price)
            .then(response => {
              this.price = response
              this.$router.push('/price/' + this.price.id)
            })
            .catch(error => {
              let data = error.response.data
              if (error.response.status === 422) {
                this.fieldsErrors = data
              } else {
                this.error = this.pretty(data)
              }
              this.loading = false
            })
        }
      },
      removePrice () {
        this.confirmDialog = false
        this.loading = true
        priceApi
          .deletePrice(this.price.id)
          .then(response => {
            // this.template = response
            this.loading = false
            this.$router.go(-1)
          }).catch(error => {
            this.error = this.pretty(error.response.data)
          })
      },

      archive () {
        this.price.status = 0
        this.savePrice()
      },
      unarchive () {
        this.price.status = 1
        this.savePrice()
      },
      pretty (value) {
        if (value.message) {
          return value.message
        }
        return JSON.stringify(value, null, 2)
      },
      formatDate (date) {
        if (!date) return null
        return moment(date).format('DD.MM.YYYY')
      },
    },
  }
</script>
