var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { id: "client-forms", fluid: "", tag: "section" } },
    [
      _vm.loading
        ? _c("v-container", [
            _c(
              "div",
              { staticClass: "text-center" },
              [
                _c("v-progress-circular", {
                  attrs: {
                    indeterminate: "",
                    size: 150,
                    width: 8,
                    color: "green"
                  }
                })
              ],
              1
            )
          ])
        : _vm._e(),
      _c(
        "v-row",
        { attrs: { justify: "center" } },
        [
          !_vm.loading
            ? _c(
                "v-col",
                {
                  staticClass: "mt-0 mb-0 pt-0 pb-0",
                  attrs: { cols: "12", md: "12" }
                },
                [
                  _c("v-breadcrumbs", {
                    staticClass: "mt-0 mb-0 pt-0 pb-0",
                    attrs: {
                      items: [
                        { text: "Ваш центр", to: "/" },
                        { text: "Цены на обучение", to: "/prices" },
                        { text: _vm.title }
                      ],
                      large: ""
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "10", lg: "8" } },
            [
              _vm.error
                ? _c(
                    "base-material-alert",
                    { attrs: { dark: "", color: "error", dismissible: "" } },
                    [_vm._v("\n        " + _vm._s(_vm.error) + "\n      ")]
                  )
                : _vm._e(),
              !_vm.loading
                ? _c(
                    "base-material-card",
                    {
                      staticClass: "px-5 py-3",
                      attrs: {
                        color: "success",
                        icon: "mdi-badge-account",
                        title: _vm.title
                      }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          staticClass: "mt-5",
                          attrs: { "lazy-validation": "" }
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { justify: "center" } },
                            [
                              _vm.price.status === 0
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "12" } },
                                    [
                                      _vm.price.status === 0
                                        ? _c(
                                            "base-material-alert",
                                            {
                                              attrs: {
                                                color: "warning",
                                                dark: "",
                                                icon: "mdi-zip-disk"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                Цены в архиве!\n              "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "9" } },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-0 pb-0 mt-0 pt-0",
                                          attrs: { cols: "12", md: "5" }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              required: "",
                                              label: "Название*",
                                              "error-messages": _vm.fieldError(
                                                "name"
                                              )
                                            },
                                            model: {
                                              value: _vm.price.name,
                                              callback: function($$v) {
                                                _vm.$set(_vm.price, "name", $$v)
                                              },
                                              expression: "price.name"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "text-center align-center mb-0 pb-0 mt-0 pt-0",
                                          attrs: { cols: "12", md: "1" }
                                        },
                                        [_c("span", [_vm._v("или")])]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass:
                                            "text-center align-center mb-0 pb-0 mt-0 pt-0",
                                          attrs: { cols: "12", md: "6" }
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              "error-messages": _vm.fieldError(
                                                "course_id"
                                              ),
                                              label: "Курс",
                                              "item-value": "id",
                                              "item-text": "name",
                                              items: _vm.courses
                                            },
                                            model: {
                                              value: _vm.price.course_id,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.price,
                                                  "course_id",
                                                  $$v
                                                )
                                              },
                                              expression: "price.course_id"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "8" } },
                                        [
                                          _c("v-textarea", {
                                            attrs: {
                                              "error-messages": _vm.fieldError(
                                                "comment"
                                              ),
                                              rows: "2",
                                              "auto-grow": "",
                                              label: "Дополнительная информация"
                                            },
                                            model: {
                                              value: _vm.price.comment,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.price,
                                                  "comment",
                                                  $$v
                                                )
                                              },
                                              expression: "price.comment"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "12", md: "4" } },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              "error-messages": _vm.fieldError(
                                                "type"
                                              ),
                                              label: "Язык/тип занятия",
                                              "item-value": "id",
                                              "item-text": "name",
                                              items: _vm.types
                                            },
                                            model: {
                                              value: _vm.price.type,
                                              callback: function($$v) {
                                                _vm.$set(_vm.price, "type", $$v)
                                              },
                                              expression: "price.type"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "3" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass:
                                        "text-center align-center mb-0 pb-0 mt-0 pt-0",
                                      attrs: { cols: "12", md: "12" }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          "error-messages": _vm.fieldError(
                                            "currency"
                                          ),
                                          label: "Валюта*",
                                          "item-value": "id",
                                          "item-text": "name",
                                          items: _vm.currencies
                                        },
                                        model: {
                                          value: _vm.price.currency,
                                          callback: function($$v) {
                                            _vm.$set(_vm.price, "currency", $$v)
                                          },
                                          expression: "price.currency"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "12", md: "12" } },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: {
                                            right: "",
                                            "max-width": "50%"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "div",
                                                      _vm._g(
                                                        {
                                                          staticClass:
                                                            "card-title font-weight-light"
                                                        },
                                                        on
                                                      ),
                                                      [
                                                        _c("v-checkbox", {
                                                          attrs: {
                                                            "error-messages": _vm.fieldError(
                                                              "action"
                                                            ),
                                                            label: "Акция",
                                                            "false-value": 0,
                                                            "true-value": 1,
                                                            messages:
                                                              "Информация о цене будет выводится с пометкой &laquo;Акция!&raquo;."
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.price.action,
                                                            callback: function(
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                _vm.price,
                                                                "action",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "price.action"
                                                          }
                                                        })
                                                      ],
                                                      1
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            false,
                                            918350764
                                          )
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              "Ставьте данную пометку только на отдельные позиции,"
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              "опция предназначена для выделения одной,"
                                            ),
                                            _c("br"),
                                            _vm._v(
                                              "максимум двух позиций в прайсе центра."
                                            )
                                          ])
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "text-center align-center mb-0 pb-0 mt-0 pt-0"
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "text-center align-center mb-0 pb-0 mt-0 pt-0",
                                  attrs: { cols: "12", md: "10" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Описание 1",
                                      "error-messages": _vm.fieldError(
                                        "price1name"
                                      )
                                    },
                                    model: {
                                      value: _vm.price.price1name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.price, "price1name", $$v)
                                      },
                                      expression: "price.price1name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "text-center align-center mb-0 pb-0 mt-0 pt-0",
                                  attrs: { cols: "12", md: "2" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Цена 1",
                                      "error-messages": _vm.fieldError("price1")
                                    },
                                    model: {
                                      value: _vm.price.price1,
                                      callback: function($$v) {
                                        _vm.$set(_vm.price, "price1", $$v)
                                      },
                                      expression: "price.price1"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "text-center align-center mb-0 pb-0 mt-0 pt-0"
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "text-center align-center mb-0 pb-0 mt-0 pt-0",
                                  attrs: { cols: "12", md: "10" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Описание 2",
                                      "error-messages": _vm.fieldError(
                                        "price2name"
                                      )
                                    },
                                    model: {
                                      value: _vm.price.price2name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.price, "price2name", $$v)
                                      },
                                      expression: "price.price2name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "text-center align-center mb-0 pb-0 mt-0 pt-0",
                                  attrs: { cols: "12", md: "2" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Цена 2",
                                      "error-messages": _vm.fieldError("price2")
                                    },
                                    model: {
                                      value: _vm.price.price2,
                                      callback: function($$v) {
                                        _vm.$set(_vm.price, "price2", $$v)
                                      },
                                      expression: "price.price2"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "text-center align-center mb-0 pb-0 mt-0 pt-0"
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "text-center align-center mb-0 pb-0 mt-0 pt-0",
                                  attrs: { cols: "12", md: "10" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Описание 3",
                                      "error-messages": _vm.fieldError(
                                        "price3name"
                                      )
                                    },
                                    model: {
                                      value: _vm.price.price3name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.price, "price3name", $$v)
                                      },
                                      expression: "price.price3name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "text-center align-center mb-0 pb-0 mt-0 pt-0",
                                  attrs: { cols: "12", md: "2" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Цена 3",
                                      "error-messages": _vm.fieldError("price3")
                                    },
                                    model: {
                                      value: _vm.price.price3,
                                      callback: function($$v) {
                                        _vm.$set(_vm.price, "price3", $$v)
                                      },
                                      expression: "price.price3"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "text-center align-center mb-0 pb-0 mt-0 pt-0"
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "text-center align-center mb-0 pb-0 mt-0 pt-0",
                                  attrs: { cols: "12", md: "10" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Описание 4",
                                      "error-messages": _vm.fieldError(
                                        "price4name"
                                      )
                                    },
                                    model: {
                                      value: _vm.price.price4name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.price, "price4name", $$v)
                                      },
                                      expression: "price.price4name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "text-center align-center mb-0 pb-0 mt-0 pt-0",
                                  attrs: { cols: "12", md: "2" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Цена 4",
                                      "error-messages": _vm.fieldError("price4")
                                    },
                                    model: {
                                      value: _vm.price.price4,
                                      callback: function($$v) {
                                        _vm.$set(_vm.price, "price4", $$v)
                                      },
                                      expression: "price.price4"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "text-center align-center mb-0 pb-0 mt-0 pt-0"
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "text-center align-center mb-0 pb-0 mt-0 pt-0",
                                  attrs: { cols: "12", md: "10" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Описание 5",
                                      "error-messages": _vm.fieldError(
                                        "price5name"
                                      )
                                    },
                                    model: {
                                      value: _vm.price.price5name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.price, "price5name", $$v)
                                      },
                                      expression: "price.price5name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "text-center align-center mb-0 pb-0 mt-0 pt-0",
                                  attrs: { cols: "12", md: "2" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Цена 5",
                                      "error-messages": _vm.fieldError("price5")
                                    },
                                    model: {
                                      value: _vm.price.price5,
                                      callback: function($$v) {
                                        _vm.$set(_vm.price, "price5", $$v)
                                      },
                                      expression: "price.price5"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "text-center align-center mb-0 pb-0 mt-0 pt-0"
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "text-center align-center mb-0 pb-0 mt-0 pt-0",
                                  attrs: { cols: "12", md: "10" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Описание 6",
                                      "error-messages": _vm.fieldError(
                                        "price6name"
                                      )
                                    },
                                    model: {
                                      value: _vm.price.price6name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.price, "price6name", $$v)
                                      },
                                      expression: "price.price6name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "text-center align-center mb-0 pb-0 mt-0 pt-0",
                                  attrs: { cols: "12", md: "2" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Цена 6",
                                      "error-messages": _vm.fieldError("price6")
                                    },
                                    model: {
                                      value: _vm.price.price6,
                                      callback: function($$v) {
                                        _vm.$set(_vm.price, "price6", $$v)
                                      },
                                      expression: "price.price6"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "text-center align-center mb-0 pb-0 mt-0 pt-0"
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "text-center align-center mb-0 pb-0 mt-0 pt-0",
                                  attrs: { cols: "12", md: "10" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Описание 7",
                                      "error-messages": _vm.fieldError(
                                        "price7name"
                                      )
                                    },
                                    model: {
                                      value: _vm.price.price7name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.price, "price7name", $$v)
                                      },
                                      expression: "price.price7name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "text-center align-center mb-0 pb-0 mt-0 pt-0",
                                  attrs: { cols: "12", md: "2" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Цена 7",
                                      "error-messages": _vm.fieldError("price7")
                                    },
                                    model: {
                                      value: _vm.price.price7,
                                      callback: function($$v) {
                                        _vm.$set(_vm.price, "price7", $$v)
                                      },
                                      expression: "price.price7"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "text-center align-center mb-0 pb-0 mt-0 pt-0"
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "text-center align-center mb-0 pb-0 mt-0 pt-0",
                                  attrs: { cols: "12", md: "10" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Описание 8",
                                      "error-messages": _vm.fieldError(
                                        "price8name"
                                      )
                                    },
                                    model: {
                                      value: _vm.price.price8name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.price, "price8name", $$v)
                                      },
                                      expression: "price.price8name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "text-center align-center mb-0 pb-0 mt-0 pt-0",
                                  attrs: { cols: "12", md: "2" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Цена 8",
                                      "error-messages": _vm.fieldError("price8")
                                    },
                                    model: {
                                      value: _vm.price.price8,
                                      callback: function($$v) {
                                        _vm.$set(_vm.price, "price8", $$v)
                                      },
                                      expression: "price.price8"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "text-center align-center mb-0 pb-0 mt-0 pt-0"
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "text-center align-center mb-0 pb-0 mt-0 pt-0",
                                  attrs: { cols: "12", md: "10" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Описание 9",
                                      "error-messages": _vm.fieldError(
                                        "price9name"
                                      )
                                    },
                                    model: {
                                      value: _vm.price.price9name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.price, "price9name", $$v)
                                      },
                                      expression: "price.price9name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "text-center align-center mb-0 pb-0 mt-0 pt-0",
                                  attrs: { cols: "12", md: "2" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Цена 9",
                                      "error-messages": _vm.fieldError("price9")
                                    },
                                    model: {
                                      value: _vm.price.price9,
                                      callback: function($$v) {
                                        _vm.$set(_vm.price, "price9", $$v)
                                      },
                                      expression: "price.price9"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "text-center align-center mb-0 pb-0 mt-0 pt-0"
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "text-center align-center mb-0 pb-0 mt-0 pt-0",
                                  attrs: { cols: "12", md: "10" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Описание 10",
                                      "error-messages": _vm.fieldError(
                                        "price10name"
                                      )
                                    },
                                    model: {
                                      value: _vm.price.price10name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.price, "price10name", $$v)
                                      },
                                      expression: "price.price10name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "text-center align-center mb-0 pb-0 mt-0 pt-0",
                                  attrs: { cols: "12", md: "2" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Цена 10",
                                      "error-messages": _vm.fieldError(
                                        "price10"
                                      )
                                    },
                                    model: {
                                      value: _vm.price.price10,
                                      callback: function($$v) {
                                        _vm.$set(_vm.price, "price10", $$v)
                                      },
                                      expression: "price.price10"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "text-center align-center mb-0 pb-0 mt-0 pt-0"
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "text-center align-center mb-0 pb-0 mt-0 pt-0",
                                  attrs: { cols: "12", md: "10" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Описание 11",
                                      "error-messages": _vm.fieldError(
                                        "price11name"
                                      )
                                    },
                                    model: {
                                      value: _vm.price.price11name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.price, "price11name", $$v)
                                      },
                                      expression: "price.price11name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "text-center align-center mb-0 pb-0 mt-0 pt-0",
                                  attrs: { cols: "12", md: "2" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Цена 11",
                                      "error-messages": _vm.fieldError(
                                        "price11"
                                      )
                                    },
                                    model: {
                                      value: _vm.price.price11,
                                      callback: function($$v) {
                                        _vm.$set(_vm.price, "price11", $$v)
                                      },
                                      expression: "price.price11"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            {
                              staticClass:
                                "text-center align-center mb-0 pb-0 mt-0 pt-0"
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "text-center align-center mb-0 pb-0 mt-0 pt-0",
                                  attrs: { cols: "12", md: "10" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Описание 12",
                                      "error-messages": _vm.fieldError(
                                        "price12name"
                                      )
                                    },
                                    model: {
                                      value: _vm.price.price12name,
                                      callback: function($$v) {
                                        _vm.$set(_vm.price, "price12name", $$v)
                                      },
                                      expression: "price.price12name"
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass:
                                    "text-center align-center mb-0 pb-0 mt-0 pt-0",
                                  attrs: { cols: "12", md: "2" }
                                },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      required: "",
                                      label: "Цена 12",
                                      "error-messages": _vm.fieldError(
                                        "price12"
                                      )
                                    },
                                    model: {
                                      value: _vm.price.price12,
                                      callback: function($$v) {
                                        _vm.$set(_vm.price, "price12", $$v)
                                      },
                                      expression: "price.price12"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "12" } },
                                [
                                  _c(
                                    "v-card-actions",
                                    { staticClass: "pl-0" },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            color: "success",
                                            "min-width": "100"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.savePrice()
                                            }
                                          }
                                        },
                                        [
                                          _vm._v(
                                            "\n                  Сохранить\n                "
                                          )
                                        ]
                                      ),
                                      _vm.price.status === 1
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "warning",
                                                "min-width": "100"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.archive()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Переместить в архив\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.price.status === 0 && _vm.price.id > 0
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                color: "warning",
                                                "min-width": "100"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.unarchive()
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Вернуть из архива\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm.price.status === 0 && _vm.price.id > 0
                                        ? _c(
                                            "v-btn",
                                            {
                                              staticClass: "ma-1",
                                              attrs: {
                                                color: "error",
                                                "min-width": "100"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.confirmDialog = true
                                                }
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                  Удалить\n                "
                                              )
                                            ]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "290" },
          model: {
            value: _vm.confirmDialog,
            callback: function($$v) {
              _vm.confirmDialog = $$v
            },
            expression: "confirmDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "headline" }, [
                _vm._v("\n        Удалить цену?\n      ")
              ]),
              _c("v-card-text", [
                _vm._v(
                  "\n        Цена будет удалена без возможности восстановления.\n      "
                )
              ]),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "darken-1", text: "" },
                      on: {
                        click: function($event) {
                          _vm.confirmDialog = false
                        }
                      }
                    },
                    [_vm._v("\n          Отмена\n        ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "error darken-1", text: "" },
                      on: {
                        click: function($event) {
                          return _vm.removePrice(_vm.price.id)
                        }
                      }
                    },
                    [_vm._v("\n          Удалить\n        ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }